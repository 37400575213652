import {
    EMerchantPreOnboardingStatus,
    IAcquiringBusinessModelSubForm,
    IAcquiringContactFirstAndLastNameSubForm,
    IAcquiringTurnoverSubForm,
    ICompanyData,
    ICompanyNameAndCVRInputModel,
    IMerchantAcquiringKyc,
    IMerchantOnboardingServicesSubForm,
    IMerchantPreOnboarding,
    ISwMerchantDetails,
    TClearhausDeliveryDelayType,
    TCountryCode,
    UserIdentityDto,
    ensureIsInteger,
    getKeys,
    hasValue,
} from "swiipe.portal.shared"
import { IAddWebshopsSubForm } from "../components/merchant/AddWebshopsForm"
import { ReactComponent as StatusPassword } from "../images/icons/statuses/status-access.svg"
import { ReactComponent as StatusCreated } from "../images/icons/statuses/status-draft.svg"
import { ReactComponent as StatusKYC } from "../images/icons/statuses/status-kyc.svg"
import { ReactComponent as StatusSent } from "../images/icons/statuses/status-sent.svg"
import { ReactComponent as StatusSigned } from "../images/icons/statuses/status-signed.svg"
import { ReactComponent as StatusViewed } from "../images/icons/statuses/status-viewed.svg"
import { IInvoicingMerchantConfig } from "../type/IInvoicing"
import { IMerchantCustomPricesSubForm, mapInvoicingMerchantConfigToCustomPriceSubForm } from "./merchantCustomPricesService"

export interface IMerchantPreOnboardingForm {
    businessModel: IAcquiringBusinessModelSubForm<TClearhausDeliveryDelayType>
    turnover: IAcquiringTurnoverSubForm
    contact: IAcquiringContactFirstAndLastNameSubForm
    language: string
    company: ICompanyNameAndCVRInputModel
    webshops: IAddWebshopsSubForm
    merchantKycMessage: string
    merchantEmailMessage: string
    merchantOfferMessage: string
    services: IMerchantOnboardingServicesSubForm
    prices?: IMerchantCustomPricesSubForm
}

export function mapMerchantPreOnboardingDetailsToForm(
    preOnboarding: IMerchantPreOnboarding,
    company: ICompanyData,
    user: UserIdentityDto,
    swMerchantDetails: ISwMerchantDetails,
    invoicingMerchantConfig?: IInvoicingMerchantConfig,
    acquiringKyc?: IMerchantAcquiringKyc
): IMerchantPreOnboardingForm {
    const services = JSON.parse(preOnboarding.servicesJson) as IMerchantOnboardingServicesSubForm
    return {
        businessModel: {
            description: acquiringKyc?.businessDescription ?? "",
            tradeName: acquiringKyc?.tradeName ?? "",
            sellPhysical: acquiringKyc?.isSellingPhysicalProducts ?? false,
            sellSubscription: acquiringKyc?.isSellingSubscriptions ?? false,
            useDropShipping: acquiringKyc?.isUsingDropShipping ?? false,
            delayComment: acquiringKyc?.delayComment ?? "",
            deliveryDelay: (acquiringKyc?.deliveryDelay as TClearhausDeliveryDelayType | undefined) ?? "5to10days",
        },
        turnover: {
            monthly: acquiringKyc?.turnoverMonthly ?? preOnboarding.estimatedValue,
            transactionsCurrency: acquiringKyc?.currency ?? preOnboarding.estimatedValueCurrency,
            transactionAmount: acquiringKyc?.avgTransactionAmount ?? 0,
        },
        contact: {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone ?? "",
        },
        language: user.language ?? "da",
        company: {
            companyAddress: company.companyAddress,
            companyCity: company.companyCity,
            companyName: company.companyName ?? "",
            companyNumber: preOnboarding.companyRegistrationNumber,
            companyPostcode: company.companyPostcode,
            country: (preOnboarding.countryCode ?? swMerchantDetails.swMerchant.countryCode) as TCountryCode,
            searchBar: preOnboarding.companyRegistrationNumber,
        },
        webshops: swMerchantDetails.webshops.map((w) => ({
            domain: w.hostName,
            platform: w.platform,
            mcc: w.mcc,
        })),
        merchantKycMessage: preOnboarding.merchantKycMessage,
        merchantEmailMessage: preOnboarding.merchantEmailMessage,
        merchantOfferMessage: preOnboarding.merchantOfferMessage,
        services: services,
        prices: mapInvoicingMerchantConfigToCustomPriceSubForm(invoicingMerchantConfig, true),
    }
}

export function mapMerchantPreOnboardingDraftToDetails(preOnboarding: IMerchantPreOnboarding): {
    preOnboarding: IMerchantPreOnboarding
    services: IMerchantOnboardingServicesSubForm
    company: ICompanyData
    user: UserIdentityDto
    prices: IMerchantCustomPricesSubForm
    acquiringKyc: IMerchantAcquiringKyc
} {
    const form = JSON.parse(preOnboarding.draftData) as IMerchantPreOnboardingForm
    return {
        preOnboarding: {
            ...preOnboarding,
            companyRegistrationNumber: form.company.companyNumber,
            countryCode: form.company.country,
            merchantEmailMessage: form.merchantEmailMessage,
            merchantKycMessage: form.merchantKycMessage,
            merchantOfferMessage: form.merchantOfferMessage,
        },
        services: form.services,
        company: {
            companyAddress: form.company.companyAddress,
            companyCity: form.company.companyCity,
            companyPostcode: form.company.companyPostcode,
            companyName: form.company.companyName,
            companyEmail: "",
            companyPhone: "",
            companyType: "",
        },
        user: {
            email: form.contact.email,
            firstName: form.contact.firstName,
            lastName: form.contact.lastName,
            phone: form.contact.phone,
            countryCode: form.company.country,
            created: new Date().toISOString(),
            fullName: "",
            isDummyEmail: false,
            isSmsDisabled: false,
            userId: "",
        },
        prices: form.prices ?? { priceOverrides: {}, customPrices: {} },
        acquiringKyc: mapMerchantPreOnboardingFormToAcquiringKyc("", [], form),
    }
}

export function mapMerchantPreOnboardingFormToAcquiringKyc(
    swMerchantId: string,
    webshopIds: string[],
    form: IMerchantPreOnboardingForm
): IMerchantAcquiringKyc {
    return {
        swMerchantId: swMerchantId,
        webshopIds: webshopIds,
        businessDescription: form.businessModel.description,
        tradeName: form.businessModel.tradeName,
        isSellingPhysicalProducts: form.businessModel.sellPhysical,
        isSellingSubscriptions: form.businessModel.sellSubscription,
        delayComment: form.businessModel.delayComment,
        deliveryDelay: form.businessModel.deliveryDelay,
        isUsingDropShipping: form.businessModel.useDropShipping,
        currency: form.turnover.transactionsCurrency,
        avgTransactionAmount: ensureIsInteger(form.turnover.transactionAmount, 0),
        turnoverMonthly: ensureIsInteger(form.turnover.monthly, 0),
    }
}

export function getMerchantPreOnboardingStatus(
    merchantPreOnboarding?: IMerchantPreOnboarding
): EMerchantPreOnboardingStatus | undefined {
    if (!merchantPreOnboarding) {
        return undefined
    }
    if (merchantPreOnboarding.isCompleted) {
        return "completed"
    }
    if (merchantPreOnboarding.isSigned) {
        return "signed"
    }
    if (merchantPreOnboarding.isSent) {
        return "sent"
    }
    return merchantPreOnboarding.isDraft ? "draft" : "created"
}

export type EMerchantPreOnboardingIconType = "draft" | "created" | "password" | "viewed" | "sent" | "signed" | "kyc"
export function getMerchantPreOnboardingIcon(iconType: EMerchantPreOnboardingIconType) {
    switch (iconType) {
        case "created":
        case "draft":
            return StatusCreated
        case "password":
            return StatusPassword
        case "sent":
            return StatusSent
        case "signed":
            return StatusSigned
        case "viewed":
            return StatusViewed
        case "kyc":
            return StatusKYC
    }
}
export function getMerchantPreOnboardingIconTitle(iconType: EMerchantPreOnboardingIconType) {
    switch (iconType) {
        case "created":
        case "draft":
            return "Created"
        case "password":
            return "Password created"
        case "sent":
            return "Sent"
        case "signed":
            return "Signed"
        case "viewed":
            return "Viewed"
        case "kyc":
            return "KYC done"
    }
}
export function getMerchantPreOnboardingIconTypeFromEventStatusType(eventStatusType: string): EMerchantPreOnboardingIconType {
    return eventStatusType.toLowerCase() as EMerchantPreOnboardingIconType
}

export const merchantPreOnboardingServicesConfig: {
    inputName: keyof IMerchantOnboardingServicesSubForm
    type: "service" | "acquiring" | "hasAcquiring"
    title: string
    hide?: boolean
    dependencies?: { inputName: keyof IMerchantOnboardingServicesSubForm; type: "hasAcquiring"; title: string }[]
}[][] = [
    [
        {
            inputName: "useAcquiringClearhaus",
            title: "Acquiring via Clearhaus",
            type: "acquiring",
            dependencies: [
                {
                    inputName: "alreadyHaveAcquiringClearhaus",
                    title: "Select if the merchant already uses Clearhaus",
                    type: "hasAcquiring",
                },
            ],
        },
    ],
    [
        { type: "service", inputName: "usePaymentsCreditCard", title: "Card Payments" },
        { type: "service", inputName: "usePaymentsMobilePayOnline", title: "MobilePay" },
        { type: "service", inputName: "usePaymentsDankort", title: "Dankort" },
        { type: "service", inputName: "usePaymentsForbrugsforeningen", title: "Forbrugsforeningen" },
        { type: "service", inputName: "usePaymentsKlarna", title: "Klarna" },
        { type: "service", inputName: "usePaymentsApplePay", title: "ApplePay" },
        { type: "service", inputName: "usePaymentsViaBill", title: "ViaBill" },
    ],
    [
        { type: "service", inputName: "usePaymentsSwish", title: "Swish" },
        { type: "service", inputName: "usePaymentsResurs", title: "Resurs" },
    ],
    [
        { type: "service", inputName: "useFeatureCheckout", title: "Checkout" },
        { type: "service", inputName: "useFeatureWinBack", title: "Win-back" },
        { type: "service", inputName: "useFeaturePlusSell", title: "Plus-sell" },
        { type: "service", inputName: "useFeatureReturnSell", title: "Return-sell" },
        { type: "service", inputName: "useFeatureAnywhereMobile", title: "Anywhere Mobile", hide: true },
    ],
]

export function getErrorForServicesSelection(services: IMerchantOnboardingServicesSubForm): string | undefined {
    const keys = getKeys(services).filter((k) => services[k])
    const configs = merchantPreOnboardingServicesConfig
        .flat()
        .map((c) => [c, ...(c.dependencies ?? [])])
        .flat()
    const selectionConfigs = keys.map((k) => configs.find((c) => c.inputName === k)).filter(hasValue)

    const selectedServiceConfigs = selectionConfigs.filter((c) => c.type === "service")
    const selectedAcquirerConfigs = selectionConfigs.filter((c) => c.type === "acquiring")
    if (selectedServiceConfigs.length === 0) {
        return "You must select at least one service"
    }
    if (
        (selectedServiceConfigs.length > 1 || selectedServiceConfigs[0].inputName !== "useFeatureCheckout") &&
        selectedAcquirerConfigs.length === 0
    ) {
        return "You must select an acquirer, except when only the checkout service is selected"
    }
    return undefined
}

export function getMerchantPreOnboardingServiceTitle(inputName: keyof IMerchantOnboardingServicesSubForm): string | undefined {
    return merchantPreOnboardingServicesConfig.flat().find((i) => i.inputName === inputName)?.title
}
